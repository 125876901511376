<template>
  <div :class="g_darkmode ? '' : 'grey lighten-5'" class="pa-4">
    <v-card v-if="dataReport" flat class="pa-4">
      <v-row no-gutters :class="g_darkmode ? 'black' : 'white'" align="center">
        <v-btn
          class="font-weight-bold mb-3"
          depressed
          @click="
            $router.push({
              name: 'ReportCard',
              query: {
                year: $route.params.year,
                grade: $route.params.grade,
                class: $route.params.class
              }
            })
          "
        >
          <v-icon class="mr-2">
            mdi-arrow-left
          </v-icon>
          {{ $t("app.back") }}
        </v-btn>
      </v-row>

      <v-row no-gutters>
        <v-card
          :class="g_darkmode ? 'black' : 'white'"
          class="rounded-lg pa-4"
          flat
          width="100%"
        >
          <v-row>
            <v-col cols="12" sm="4" md="3" class="divider">
              <div class="body-2">{{ $t("app.name") }}</div>
              <div class="font-weight-bold">{{ student.student_name }}</div>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="divider">
              <div class="body-2">{{ $t("app.registration_number") }}</div>
              <div class="font-weight-bold">
                {{ student.student_nis }}
              </div>
            </v-col>
            <v-col cols="6" sm="4" md="1" class="divider">
              <div class="body-2">{{ $t("app.grade") }}</div>
              <div class="font-weight-bold">{{ student.grade_name }}</div>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="divider">
              <div class="body-2">{{ $t("app.class") }}</div>
              <div class="font-weight-bold">{{ student.class_name }}</div>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="divider">
              <div class="body-2">Semester</div>
              <div class="font-weight-bold">{{ student.semester }}</div>
            </v-col>
            <v-col cols="6" sm="4" md="2">
              <div class="body-2">{{ $t("app.school_year") }}</div>
              <div class="font-weight-bold">{{ student.periode }}</div>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <ReportCardDetailForm :dataReport="dataReport" />
    </v-card>
    <div v-else>
      Loading ....
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportCardDetailForm from "./ReportCardDetailForm";
import { getDetailReportCard } from "@/api/admin/academic/reportCard";

export default {
  components: {
    ReportCardDetailForm
  },
  data() {
    return {
      valid: false,
      student: {},
      dataReport: null,
      loadingSave: false
    };
  },
  computed: {
    ...mapGetters(["g_darkmode"])
  },
  async mounted() {
    this.dataReport = null;
    const id = this.$route.params.id;
    const detailReport = await getDetailReportCard({ id: id });
    this.student = detailReport.data.data.header;
    this.dataReport = detailReport.data.data;
    this.dataReport.partical_work =
      this.dataReport.partical_work && this.dataReport.partical_work != 0
        ? this.dataReport.partical_work
        : "-";
    this.dataReport.summary =
      this.dataReport.summary && this.dataReport.summary != 0
        ? this.dataReport.summary
        : "-";
  },
  methods: {
    triggerSave() {}
  }
};
</script>

<template>
  <v-form v-if="dataReport" ref="formReport">
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-btn
        :loading="loading"
        class="gradient-primary"
        dark
        depressed
        @click="save"
      >
        {{ $t("app.save") }}
      </v-btn>
    </v-row>

    <v-card flat class="py-4 mt-4" style="height: 55vh; overflow: auto">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            {{ $t("report_card.attitude_score_title") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="[
                {
                  text: $t('report_card.attitude_score_attitude'),
                  value: 'name',
                  sortable: false
                },
                {
                  text: $t('report_card.score_title'),
                  value: 'score',
                  sortable: false
                },
                {
                  text: $t('report_card.attitude_score_rating'),
                  value: 'predicate',
                  sortable: false
                },
                {
                  text: $t('app.description'),
                  value: 'description',
                  sortable: false
                }
              ]"
              :items="dataReport.atitude"
              class="elevation-0"
              hide-default-footer
            >
              <template v-slot:[`item.score`]="{ item }">
                <v-text-field
                  v-model="item.score"
                  :placeholder="$t('app.score')"
                  class="pt-3"
                  required
                  dense
                  outlined
                />
              </template>
              <template v-slot:[`item.predicate`]="{ item }">
                <v-select
                  v-model="item.grade"
                  :rules="[v => !!v || $t('app.required')]"
                  :items="predicateAttitude"
                  item-text="kode"
                  item-value="kode"
                  :placeholder="$t('report_card.attitude_score_rating')"
                  class="pt-3"
                  dense
                  outlined
                  required
                  @change="setDescription(item.grade, item, 'attitude')"
                />
              </template>
              <template v-slot:[`item.description`]="{ item }">
                <v-text-field
                  v-model="item.description"
                  :rules="[v => !!v || $t('app.required')]"
                  :placeholder="$t('app.description')"
                  class="pt-3"
                  required
                  dense
                  outlined
                />
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            {{ $t("report_card.score_title") }}
            <!-- <v-spacer></v-spacer> -->
            <!-- {{ $t("report_card.completeness_criteria") }}
          <span class="ml-2">= {{ dataReport.completeness_criteria }}</span> -->
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- dataReport.score -->
            <div v-for="(s, idx) in dataReport.score" :key="`${idx}-score`">
              <v-row v-if="s.lesson_group" no-gutters class="mb-2">
                <div>Kelompok {{ s.lesson_group }} :</div>
                <div class="font-weight-bold ml-2">
                  {{ s.lesson_group_name }}
                </div>
              </v-row>
              <div v-else class="mt-4"></div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" rowspan="2">
                        {{ $t("app.subject") }}
                      </th>
                      <th class="text-left" colspan="3">
                        Nilai Pengetahuan (K-3)
                      </th>
                      <th class="text-left" colspan="3">
                        Nilai Pengetahuan (K-4)
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left">Angka</th>
                      <th class="text-left">Predikat</th>
                      <th class="text-left">Deskripsi</th>
                      <th class="text-left">Angka</th>
                      <th class="text-left">Predikat</th>
                      <th class="text-left">Deskripsi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(m, idxm) in s.member" :key="idxm">
                      <td>{{ m.lesson_master_name }}</td>
                      <td>
                        <v-text-field
                          v-model="m.ki_3"
                          :rules="[v => !!v || $t('app.required')]"
                          class="width150 pt-3"
                          required
                          dense
                          outlined
                          @keypress="scoreValidation"
                          @blur="
                            setScore({
                              value: m.ki_3,
                              idx: idx,
                              idxm: idxm,
                              key: 'ki_3'
                            })
                          "
                        />
                      </td>
                      <td>
                        <v-select
                          v-model="m.ki_3_grade"
                          :rules="[v => !!v || $t('app.required')]"
                          :items="predicate"
                          item-text="kode"
                          item-value="kode"
                          class="width150 pt-3"
                          dense
                          outlined
                          required
                        />
                        <!-- @change="
                            setDescription(
                              m.ki_3_grade,
                              { idx: idx, idxm: idxm },
                              'score',
                              'ki_3_desc'
                            )
                          " -->
                      </td>
                      <td>
                        <v-text-field
                          v-model="m.ki_3_desc"
                          :rules="[v => !!v || $t('app.required')]"
                          class="width200 pt-3"
                          required
                          dense
                          outlined
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="m.ki_4"
                          :rules="[v => !!v || $t('app.required')]"
                          class="width150 pt-3"
                          required
                          dense
                          outlined
                          @keypress="scoreValidation"
                          @blur="
                            setScore({
                              value: m.ki_4,
                              idx: idx,
                              idxm: idxm,
                              key: 'ki_4'
                            })
                          "
                        />
                      </td>
                      <td>
                        <v-select
                          v-model="m.ki_4_grade"
                          :rules="[v => !!v || $t('app.required')]"
                          :items="predicateAttitude"
                          item-text="kode"
                          item-value="kode"
                          class="width150 pt-3"
                          dense
                          outlined
                          required
                          @change="
                            setDescription(
                              m.ki_4_grade,
                              { idx: idx, idxm: idxm },
                              'score',
                              'ki_4_desc'
                            )
                          "
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="m.ki_4_desc"
                          :rules="[v => !!v || $t('app.required')]"
                          class="width200 pt-3"
                          required
                          dense
                          outlined
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!-- <div class="mt-2">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td colspan="2">Total Nilai</td>
                      <td colspan="3">
                        <v-text-field
                          v-model="dataReport.score_summary.total_ki3"
                          :rules="[v => !!v || $t('app.required')]"
                          :label="'Total K-3'"
                          required
                          class="pt-3 width150"
                          dense
                          outlined
                          @keypress="scoreValidation"
                          @blur="
                            setScoreTotal({
                              value: dataReport.score_summary.total_ki3,
                              key: 'total_ki3'
                            })
                          "
                        />
                      </td>
                      <td colspan="3">
                        <v-text-field
                          v-model="dataReport.score_summary.total_ki4"
                          :rules="[v => !!v || $t('app.required')]"
                          :label="'Total K-4'"
                          required
                          class="pt-3 width150"
                          dense
                          outlined
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">Rata - Rata</td>
                      <td colspan="3">
                        <v-text-field
                          v-model="dataReport.score_summary.avg_ki3"
                          :rules="[v => !!v || $t('app.required')]"
                          :label="'Rata-rata K-3'"
                          required
                          class="pt-3 width150"
                          dense
                          outlined
                        />
                      </td>
                      <td colspan="3">
                        <v-text-field
                          v-model="dataReport.score_summary.avg_ki4"
                          :rules="[v => !!v || $t('app.required')]"
                          :label="'Rata-rata K-4'"
                          required
                          class="pt-3 width150"
                          dense
                          outlined
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div> -->
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            {{ $t("report_card.extracuricular") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table v-if="dataReport.extracurricullar.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="width: 60px">
                      {{ $t("report_card.number") }}
                    </th>
                    <th class="text-left">
                      {{ $t("report_card.extracuricular_activities") }}
                    </th>
                    <th class="text-left">
                      {{ $t("report_card.extracuricular_rating") }}
                    </th>
                    <th class="text-left">{{ $t("app.description") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(e, idx) in dataReport.extracurricullar"
                    :key="`${idx}-attitude`"
                  >
                    <td style="width: 60px">{{ idx + 1 }}.</td>
                    <td class="width200">{{ e.exschool_name }}</td>
                    <td>
                      <v-select
                        v-model="e.grade"
                        :rules="[v => !!v || $t('app.required')]"
                        :items="predicate"
                        item-text="kode"
                        item-value="kode"
                        class="width150 pt-3"
                        dense
                        outlined
                        required
                        @change="setDescription(e.grade, idx, 'extra')"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="e.description"
                        :rules="[v => !!v || $t('app.required')]"
                        class="width150 pt-3"
                        required
                        dense
                        outlined
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else>{{ $t("app.no_data") }}</div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            {{ $t("report_card.achievement") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table v-if="dataReport.achievement.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="width: 60px">
                      {{ $t("report_card.number") }}
                    </th>
                    <th class="text-left">{{ $t("app.description") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(a, idx) in dataReport.achievement"
                    :key="`${idx}-attitude`"
                  >
                    <td style="width: 60px">{{ idx + 1 }}.</td>
                    <td>
                      <v-text-field
                        v-model="a.description"
                        :rules="[v => !!v || $t('app.required')]"
                        class="width200 pt-3"
                        required
                        dense
                        outlined
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else>{{ $t("app.no_data") }}</div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            {{ $t("report_card.absence") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="width: 60px">
                      {{ $t("report_card.number") }}
                    </th>
                    <th class="text-left">
                      {{ $t("report_card.absence_type") }}
                    </th>
                    <th class="text-left">{{ $t("elearning.table.total") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>{{ $t("report_card.sick") }}</td>
                    <td>{{ dataReport.absence.sick }}</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>{{ $t("report_card.leave") }}</td>
                    <td>{{ dataReport.absence.leave }}</td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>{{ $t("report_card.alpha") }}</td>
                    <td>{{ dataReport.absence.alpha }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="mt-6">
        <div>
          <v-textarea
            v-model="dataReport.partical_work"
            :label="$t('report_card.field_practice')"
            :rules="[v => !!v || $t('app.required')]"
            required
            outlined
            dense
            rows="3"
            auto-grow
          />
        </div>
        <div>
          <v-textarea
            v-model="dataReport.summary"
            :label="$t('report_card.homeroom_notes')"
            :rules="[v => !!v || $t('app.required')]"
            required
            outlined
            dense
            rows="3"
            auto-grow
          />
        </div>
      </div>

      <v-card flat class="mt-6">
        <v-row no-gutters justify="space-around">
          <div class="text-center">
            <div class="mb-6">
              Mengetahui,<br />
              Orang Tua/Wali
            </div>

            <div class="pt-12">( {{ dataReport.footer.guardian.name }} )</div>
          </div>
          <div class="text-center">
            <div class="mb-10">
              Wali Kelas
            </div>

            <div class="pt-8">
              {{ dataReport.footer.homeroom.name }}<br />
              NIP: {{ dataReport.footer.homeroom.nip }}
            </div>
          </div>
        </v-row>
      </v-card>
    </v-card>
  </v-form>
</template>

<script>
import {
  getPredicateList,
  editReportCard
} from "@/api/admin/academic/reportCard";

export default {
  props: {
    dataReport: Object
  },
  data() {
    return {
      panel: [],
      predicateAttitude: [],
      predicate: [],
      loading: false
    };
  },
  mounted() {
    getPredicateList().then(res => {
      if (res.data.code) {
        const data = res.data.data;
        this.predicateAttitude = data.filter(r => r.type === "K1K2");
        this.predicate = data.filter(r => r.type === "K3K4");
      }
    });
  },
  methods: {
    save() {
      this.panel = [0, 1, 2, 3, 4];
      setTimeout(() => {
        this.dataReport.header.id = this.$route.params.id;
        if (this.$refs.formReport.validate()) {
          this.loading = true;
          editReportCard(this.dataReport)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.closeModal("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loading = false;
            })
            .catch(() => (this.loading = false));
        } else {
          this.$nextTick(() => {
            const element = document.querySelector(".v-input.error--text");
            if (element) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }
          });
        }
      }, 200);
    },
    setScore(data) {
      const parse = parseFloat(data.value);
      const precentageRegx = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
      const x = precentageRegx.test(parse);
      if (x) {
        this.dataReport.score[data.idx].member[data.idxm][data.key] =
          data.value;
      } else if (parse <= 0) {
        this.dataReport.score[data.idx].member[data.idxm][data.key] = 0;
      } else if (parse >= 100) {
        this.dataReport.score[data.idx].member[data.idxm][data.key] = 100;
      }
    },
    setScoreTotal(data) {
      const parse = parseFloat(data.value);
      const precentageRegx = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
      const x = precentageRegx.test(parse);
      if (x) {
        this.dataReport.score_summary[data.key] = data.value;
      } else if (parse <= 0) {
        this.dataReport.score_summary[data.key] = 0;
      } else if (parse >= 100) {
        this.dataReport.score_summary[data.key] = 100;
      }
    },
    scoreValidation(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 190
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setDescription(kode, idx, type, key) {
      switch (type) {
        case "attitude":
          {
            const getIdx = this.dataReport.atitude
              .map(r => r.ki)
              .indexOf(idx.ki);
            this.dataReport.atitude[
              getIdx
            ].description = this.predicateAttitude.find(
              r => r.kode == kode
            ).description;
          }
          break;
        case "score":
          this.dataReport.score[idx.idx].member[idx.idxm][
            key
          ] = this.predicate.find(r => r.kode == kode).description;
          break;
        case "extra":
          this.dataReport.extracurricullar[
            idx
          ].description = this.predicate.find(r => r.kode == kode).description;
          break;

        default:
          break;
      }
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-4",class:_vm.g_darkmode ? '' : 'grey lighten-5'},[(_vm.dataReport)?_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-row',{class:_vm.g_darkmode ? 'black' : 'white',attrs:{"no-gutters":"","align":"center"}},[_c('v-btn',{staticClass:"font-weight-bold mb-3",attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({
            name: 'ReportCard',
            query: {
              year: _vm.$route.params.year,
              grade: _vm.$route.params.grade,
              class: _vm.$route.params.class
            }
          })}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-arrow-left ")]),_vm._v(" "+_vm._s(_vm.$t("app.back"))+" ")],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"rounded-lg pa-4",class:_vm.g_darkmode ? 'black' : 'white',attrs:{"flat":"","width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"divider",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("app.name")))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.student.student_name))])]),_c('v-col',{staticClass:"divider",attrs:{"cols":"6","sm":"4","md":"2"}},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("app.registration_number")))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.student.student_nis)+" ")])]),_c('v-col',{staticClass:"divider",attrs:{"cols":"6","sm":"4","md":"1"}},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("app.grade")))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.student.grade_name))])]),_c('v-col',{staticClass:"divider",attrs:{"cols":"6","sm":"4","md":"2"}},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("app.class")))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.student.class_name))])]),_c('v-col',{staticClass:"divider",attrs:{"cols":"6","sm":"4","md":"2"}},[_c('div',{staticClass:"body-2"},[_vm._v("Semester")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.student.semester))])]),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("app.school_year")))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.student.periode))])])],1)],1)],1),_c('ReportCardDetailForm',{attrs:{"dataReport":_vm.dataReport}})],1):_c('div',[_vm._v(" Loading .... ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }